<template>
  <v-data-table
    :headers="headers"
    :items="usuarios"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title> Administración de usuarios </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
          <v-dialog persistent v-model="dialogUsuarios" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
                Adicionar
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Nuevo usuario</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Correo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.nombres"
                        label="Nombres"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.apellidos"
                        label="Apellidos"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="getRoles"
                        label="Roles"
                        multiple
                        v-model="editedItem.roles"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.password"
                        label="Constraseña"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="editedItem.estado"
                        label="Activo"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogUsuarios = false"
                >
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialogUsuarios: false,
    //roles: ["admin", "docente", "estudiante", "acudiente"],
    usuarios: [],
    headers: [
      {
        text: "Correo",
        align: "start",
        value: "email",
      },
      { text: "Nombre", value: "nombres" },
      { text: "Apellido", value: "apellidos" },
      { text: "Rol", value: "roles" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id:"",
      email: "",
      nombres: "",
      apellidos: "",
      roles: [],
      estado: true,
      password:""
    },
    defaultItem:{
      id:"",
      email: "",
      nombres: "",
      apellidos: "",
      roles: [],
      estado: true,
      password:""
    }
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["getRoles"]),
  },
  watch: {
    dialogUsuarios(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions(["GetUsuarios","RegistrarUsuario","UpdateUsuario"]),
    initialize() {
      this.GetUsuarios().then((x) => (this.usuarios = x.data));
    },
    editItem(item) {
      this.editedIndex = this.usuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUsuarios = true;
    },
    save() {
      if (this.editedIndex > -1) {
        //editar
        this.UpdateUsuario(this.editedItem).then((x)=>{
          Object.assign(this.usuarios[this.editedIndex], x.data);
          this.close();
        });
     
      } else {
        //nuevo
        this.RegistrarUsuario(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.usuarios.push(this.editedItem);
            this.close();
          }
        });
      }
      
    },
    close() {
      this.dialogUsuarios = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>