<template>
    <v-container>
        <Usuarios />
    </v-container>
</template>
<script>
import Usuarios from "@/components/ResitrarUsuarios.vue"
export default {
    components: {
        Usuarios
    }
}
</script>